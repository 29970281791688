/* ==========================================================================
   Views (Summary, Gallery)
   ========================================================================== */

@import 'typography';

.no-content {
  margin: 150px auto 100px;
  text-align: center;

  h2 {
    @include headline-m();
  }

  h3 {
    @include headline-s();
  }
}

section.view {
  position: relative;
  @include group;
  min-height: 250px;

  @media screen and (min-height: 950px) {
    min-height: $min-height-content;
  }

  .summary-content {
    .no-content {
      margin: 160px auto;
    }
  }
  .column {
    .no-content {
      margin-top: 220px;
    }
  }
}

.loader-container {
  position: relative;
  padding: 25px 0 10px;
}

#upas {
  span.tooltip.beware {
    background: $red;
    max-width: 360px;
    > .nub {
      border-color: $red transparent transparent transparent;
      left: 10px;
    }
  }
}

.summary-content-filter-hd {
  position: relative;
  @include paragraph-s(var(--space-xs), var(--space-xs), 300);
  color: var(--color-text-primary-enabled-weak);;
  height: 20px;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
}
.summary-content-filter-hd__name {
  padding-right: 10px;
  &:last-child {
    padding-right: 0;
    padding-left: 10px;
  }
}
.upas {
  @include no-bullet;
  display: flex;
  flex-flow: row wrap;
  gap: var(--space-s);
  padding-bottom: var(--space-s);
  > li {
    padding: 0;
  }
}
.grid-item--fixed {
  float: left;
  width: $fixed-grid-item-width;
  &.grid-item--nopadding {
    padding: 0 !important;
  }
}
.grid-item {
  border: 1px solid transparent;
  overflow: hidden;
  position: relative;
  @include group;
  transition: border 200ms ease-out, opacity 200ms ease-out;

  &.grid-item__primary.ng-enter,
  &.grid-item__primary.ng-leave.ng-leave-active {
    display: none !important;
  }
  &:hover,
  &.active,
  &.pending {
    border-color: $light-gray;
    #observers & {
      border-color: transparent;
    }
    .grid-item-actions {
      visibility: visible;
      opacity: 1;
    }
  }
  &.pending {
    color: $medium-gray;
    display: table;
    height: 177px;
    margin-bottom: 39px;
    width: 100%;
    .rh-loader-container {
      padding-top: 76px;
    }
    a {
      color: $medium-gray;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
    }
    a {
      @include default-transition;
      &:hover {
        background: $bg-gray;
      }
    }
    h4 {
      color: $medium-gray;
      font-size: 24px;
      line-height: 30px;
      margin: 0 0 5px;
    }
    p {
      font-size: 16px;
      line-height: 20px;
      margin: 0;
    }
  }
  &.untranslated {
    .grid-item-content {
      .upa-icon,
      .upa-headline,
      a,
      h4,
      p {
        color: $medium-gray;
        opacity: 0.5;
      }
    }
  }
  .grid-item-content {
    min-height: 202px;
  }
  .grid-item-actions {
    position: relative;
    @include no-bullet;
    @include group;
    margin: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease-out;
    li {
      border-top: 1px solid $light-gray;
      border-right: 1px solid $light-gray;
      float: left;
      &:last-child {
        border: 0;
      }
      .draft & {
        width: 28%;
      }
      .published &,
      .disabled & {
        width: 42%;
      }
      .pending & {
        width: 84%;
      }
      &.grid-item-action--show-disable {
        width: 84%;
      }
      #team & {
        border: 0;
        float: none;
        a {
          padding: 10px 0;
        }
      }
      &.action-more {
        border-right: 0 !important;
        width: 16%;

        > button {
          padding: 8px 0;
        }
        &.active {
          border-top-color: $bg-gray;
        }
      }
      &.more-menu {
        position: absolute;
        top: -117px;
        right: 0;
        bottom: 39px;
        width: 42%;
        transition: top 0.2s ease-out;

        &.more-menu__extended {
          top: -156px;
        }
        &.more-menu__limited {
          top: -78px;
        }
        &.ng-hide-add,
        &.ng-hide-remove {
          transition: top 0.2s ease-out;
          z-index: -1;
        }
        &.ng-hide,
        &[hidden] {
          top: 0;
          z-index: -1;
        }
      }
      .more-menu-list {
        @include no-bullet;
        border-left: 1px solid $light-gray;
      }
      .more-menu-item {
        border-top: 1px solid $light-gray;
        border-right: none;
        float: none;
        width: 100% !important;
      }
      a,
      button,
      label {
        background: var(--color-element-fill-inverse-enabled-weak);
        border-radius: 0;
        color: $dark-gray;
        cursor: pointer;
        display: block;
        font-size: 14px;
        line-height: 14px;
        margin: 0;
        padding: 12px 0;
        text-align: center;
        width: 100%;
        .csstransitions & {
          transition: background 200ms ease-out, color 200ms ease-out;
        }
        &:hover {
          background: var(--color-element-fill-inverse-enabled-weak);
        }
        &:focus {
          outline: 0;
        }
      }
      .delete {
        background: transparent;
        color: $medium-gray;
        bottom: 124px;
        width: auto;
        padding: 0;
        right: 3px;
        &:focus {
          outline: none;
        }
        &:focus,
        &:hover {
          background: transparent;
          color: $dark-gray;
        }
      }
    }
  }
  .draft &.unsaved {
    .grid-item-actions {
      li {
        width: 42%;
        &.action-more {
          width: 16%;
        }
      }
    }
  }
  .disabled &.unsaved {
    .grid-item-actions {
      li {
        width: 84%;
        &.action-more {
          width: 16%;
        }
      }
    }
  }
  &.loader {
    &:hover {
      border-color: transparent;
    }
    #photos & {
      > div {
        border: 1px solid #e0e0e0;
        margin: 22px 4px 0;
        position: relative;
        width: 100px;
        height: 100px;
      }
    }
    #icons & {
      border: 1px solid #e0e0e0;
      min-height: 0;
      > div {
        position: relative;
        height: 104px;
      }
    }
  }
  figure {
    margin: 0;
  }
}

.upa-icon {
  padding-top: 40px;
  text-align: center;
}

.upa-message {
  padding-top: 10px;
  padding-left: 0;
  .meta,
  .upa-links {
    @include no-bullet;
    @include group;
    li {
      float: left;
      margin-right: 10px;
    }
  }
  .meta {
    color: $medium-gray;
    font-size: 11px;
    line-height: 11px;
    margin-bottom: 10px;
    .rh-glyphs-clock {
      border-bottom: none;
      color: $medium-gray;
      position: relative;
      padding: 10px 10px 10px 5px;
      left: -5px;
      &:hover {
        color: $dark-gray;
      }
    }
    .rh-glyphs-beware {
      border-bottom: none;
      color: $red;
      position: relative;
      padding: 10px 10px 10px 5px;
      left: -5px;
    }
    .beware {
      text-transform: uppercase;
    }
  }
  .upa-headline {
    color: $dark-gray;
    font-size: 15px;
    font-weight: 600;
    line-height: 15px;
    margin: 0 0 5px;
  }
  .upa-description {
    color: $dark-gray;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
  .upa-links {
    border-top: 1px solid $light-gray;
    font-size: 13px;
    line-height: 13px;
    margin: 10px 0 0;
    padding-top: 10px;
  }
}

/* ==========================================================================
   UPAs: All
   ========================================================================== */

.targeting__category {
  margin: 0 12px 12px 0;
}
.selected-categories li span.category-cannot-remove {
  padding-right: 12px;
  a,
  .rh-btn__clear {
    display: none;
  }
}

/* ==========================================================================
   UPAs: Summary
   ========================================================================== */
#upas-summary {
  .header {
    background: var(--color-element-fill-inverse-enabled-weak);;
  }
  .page-header {
    transition: top 200ms ease-out, left 200ms ease-out;
    background: var(--color-element-fill-inverse-enabled-weak);;
    height: 50px;
    z-index: 1;
  }
  .rh-menu-panel--closed + .rh-main-panel .page-header {
    left: 0;
  }
  .page-header--hidden,
  .main-panel-child--hidden .page-header {
    transition: top 200ms ease-out;
    top: $header-height - 65px;
  }
  .page-header--scrolled,
  .main-panel-child--scrolled .page-header {
    @include default-dropshadow-dark;
  }
  .summary-content {
    transition: height 200ms ease-out;
    padding-top: 5px;

    &.column {
      margin-left: 250px;
      min-height: 570px;
    }
  }
}
.rh-upa-filter {
  display: block;
  padding: 0 0 40px;
}

/* ==========================================================================
   UPAs: Basics / Media
   ========================================================================== */
#upas_,
#upas_basics,
#upas_media,
#upas_targeting {
  header {
    .icon {
      height: 16px;
      width: 16px;
    }
    .inline-header__icon-button {
      margin-left: auto;
    }
    h3 {
      font-size: 20px;
      font-weight: 400;
      line-height: 16px;
      margin: 0;
      vertical-align: middle;
    }
    .page-header__ua-title {
      font-size: 20px;
      line-height: 16px;
      margin: 0 10px 0 7px;
      vertical-align: middle;
    }
    .page-header__ua-category,
    .page-header__ua-workflow-state {
      position: relative;
      color: $medium-gray;
      font-size: 16px;
      line-height: 16px;
      text-transform: uppercase;
      vertical-align: middle;
    }
  }

  .unselected {
    .unselected-gallery-list {
      height: 400px;
      overflow-y: auto;
      padding: var(--space-s) var(--space-m);
      position: relative;
      figure {
        margin: 0;
        width: 150px;
        height: 100px;
        display: grid;
        place-items: center;

        .grid-item-img.loading {
          width: unset;
        }
      }
      &.list-view {
        .unselected-gallery-item {
          float: none;
          margin: 0 0 10px;
        }
        figure {
          margin-right: 20px;
        }
      }
      .unselected-gallery-item {
        border: 3px solid transparent;
        cursor: pointer;
        float: left;
        margin: 0 10px 10px 0;
        @include group;
        &:hover {
          border-color: $brand-blue;
        }
        &.active {
          border-color: $brand-blue;
          img {
            opacity: 0.5;
          }
        }
        &.loader {
          cursor: default;
          > div {
            position: relative;
          }
        }
        .csstransitions & {
          transition: border-color 100ms ease-in;
        }
      }
      img {
        .csstransitions & {
          transition: opacity 200ms ease-out;
        }
      }
    }
  }
}
.btn__extra {
  font-size: 10px;
  height: 36px;
  min-width: inherit !important;
  margin-left: 1px;
  .rh-glyphs-down-arrow {
    border-top-color: #fff;
  }
}
.workflow-actions__list {
  @include no-bullet;
  width: 100%;
}
.workflow-actions__item {
  .workflow-actions__link {
    color: $dark-gray;
  }
}
/* ==========================================================================
   UPAs: Basics
   ========================================================================== */
$form-dd-width: 166px;
#upas_basics {
  fieldset {
    width: 100%;
  }
  .form-rh dl dt {
    width: $form-dd-width;
  }
  .form-rh dl dd {
    width: calc(100% - #{$form-dd-width});
  }
  .unselected {
    width: 60%;
    .unselected-gallery-list {
      height: 200px;
      li.loader {
        &:hover {
          border-color: transparent;
        }
        > div {
          width: 84px;
          height: 84px;
        }
      }
      &.list-view {
        .added {
          margin-bottom: 8px;
        }
      }
    }
  }
  .icon-container {
    figure {
      display: inline-block;
      margin: 0 10px 0 0;
      padding-right: 110px;
      position: relative;
      text-align: center;
      width: 50%;
    }
  }
  .icon__delete-button {
    position: absolute;
    top: 0;
    right: 40%;
  }
}
.uta-headline,
.uta-description {
  background-color: #eee;
  cursor: not-allowed;
  opacity: 1;
}
.language-default-hint {
  color: $medium-gray;
  display: inline-block;
  max-width: 320px;

  &:hover {
    color: $medium-gray;
  }
}
.language-default-hint__nowrap {
  @include text-ellipsis;
}
.form-basics__select {
  width: 40%;
  select {
    width: 100%;
  }
}
.form-row__weight {
  label {
    float: left;
    line-height: 36px;
    margin: 0 12px 0 -2px;
  }
}
.form-basics__weight-input {
  float: left;
  width: 100px !important;
}
.form-row__switch {
  .rh-switch {
    margin-top: 8px;
  }
  .label-fee-text {
    float: left;
    margin: 6px 11px 0 8px;
    width: 28px;
  }
  .fee-inputs {
    float: left;
    @include no-bullet;
    width: 248px;
  }
  .fee-input-row {
    margin-bottom: 10px;
  }
  .fee-input-container {
    position: relative;
    float: left;
    width: 46%;
  }
  .fee-input {
    padding-right: 3px;
    text-indent: 37px;
    width: 100% !important;

    &.fee-input__max {
      text-indent: 2px;
    }
    &.ng-invalid {
      border-color: $red;
      color: $red;
    }
  }
  .fee-currency {
    position: absolute;
    top: 0;
    left: 12px;
    line-height: 36px;
    text-align: right;
    width: 30px;
    z-index: 10;
  }
  .range-dash {
    float: left;
    line-height: 36px;
    text-align: center;
    width: 8%;
  }
}

/* ==========================================================================
   UPAs: Targeting
   ========================================================================== */
#upas_targeting {
  --targeting-form-max-width: 555px;
  .targeting-form__headline {
    @include headline-xs(0, var(--space-xs), 700);
  }
  .targeting-form {
    max-width: $global-width;
    width: 100%;
    align-self: flex-start;
    rule-form {
      .typeahead__clear {
        display: none;
      }
    }
  }
  .form-rh > fieldset {
    width: 100%;
    > dl {
      > dt {
        width: $form-dd-width;
        float: left;
      }
      > dd {
        width: 66%;
        &.targeting-form {
          width: calc(100% - #{$form-dd-width});
        }
      }
      .cb-cabin--fare-types {
        float: none;
        padding: 7px 0 6px;

        &:first-of-type {
          padding-top: 10px;
          > label {
            &:before {
              top: 10px;
            }
          }
        }

        input[type="checkbox"] {
          margin-bottom: 0;
          top: 6px;
        }
        label {
          &:before {
            top: 6px;
          }
        }
        .cabin__fare-types {
          margin-left: 25px;
        }
        .cb {
          float: none;
          padding: 7px 0 1px;
        }
      }
      textarea {
        display: block;
        width: 100%;
      }
      .targeting-date-text {
        display: inline;
        line-height: 36px;
      }
      .targeting-date {
        display: inline-block;
        margin: 0 5px;
      }
      .rh-datepicker {
        vertical-align: top;
        width: 110px;
      }
      .campaign-dates {
        .cb {
          margin-right: 10px;
        }
        input.input {
          display: inline-block;
          width: 120px;
        }
        > em {
          font-style: normal;
          margin-right: 10px;
        }
        .datepicker-container {
          display: inline-block;
          margin-right: 10px;
        }
      }
    }
  }
  .notes {
    max-width: var(--targeting-form-max-width, 555px);
    margin-bottom: var(--space-m);
  }
  .rules-builder {
    margin-bottom: var(--space-m);
    .rules {
      @include no-bullet;
      > li {
        margin-bottom: var(--space-s);
        position: relative;
        @include group;
      }
      .select {
        float: left;
        margin-right: 10px;
        select {
          margin: 0;
          width: 100%;
        }
      }
    }
    input.input {
      margin: 0;
    }
    .type,
    .btn-lg {
      width: 30%;
    }
    .operator {
      width: 14%;
    }
    .list {
      float: left;
      width: calc(50% - 28px);
      @media screen and (min-width: 1180px) {
        width: calc(50% - 17px);
      }
      &.list__o_d {
        width: 45%;
      }
      > div {
        width: 100%;
      }
      .select {
        float: none;
        margin: 0;
      }
    }
    .range {
      @include group;
      input.input,
      .range-dash {
        float: left;
      }
      input.input {
        margin: 0;
      }
      .range-dash {
        line-height: 36px;
        text-align: center;
        width: 10%;
      }
      .datepicker-container {
        float: left;
        width: 45%;
        input.input {
          float: none;
          width: 100%;
        }
      }
    }
    .duration-container,
    .value-container {
      float: left;
      width: 45%;
      border: 1px solid var(--color-background-primary-enabled-strong);
      background-color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-weight: normal;
      line-height: 22px;
      margin-top: 0;
      margin-bottom: 0;
      border-radius: 4px;
      padding: var(--space-xs);
      line-height: var(--space-m);
      font-size: var(--space-s);
      height: 40px;

      &.has-error {
        border-color: $alert;
      }

      input,
      label {
        border: 0;
        display: inline;
        margin: 0;
        padding: 0;
        line-height: 16px;
        text-align: center;
        width: 32px;
        &:focus {
          outline: none;
        }
      }
      .duration-divider {
        margin: 0;
        padding: 0;
        line-height: 16px;
        text-align: center;
      }
      input {
        padding: 0 2px;
        text-align: right;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      label {
        color: $medium-gray;
      }
    }
    .value-container {
      input {
        padding: 0;
      }
      .duration__minute {
        text-align: left;
      }
    }
    .rule__or {
      position: absolute;
      margin-top: -8px;
      top: 50%;
      right: 20px;
      line-height: 1;
      display: block;
      padding: 0 6px;
      input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        &:checked + label {
          color: $brand-blue;
        }
      }
      label {
        display: block;
        cursor: pointer;
      }
    }

    .delete {
      position: absolute;
      margin-top: -8px;
      top: 50%;
      right: 0;
    }
  }
}
.targeting-cabins-mode {
  padding-bottom: 8px;
}
.targeting-cabins-mode__radio-container {
  padding-bottom: 4px;
}
.targeting-cabins-mode__container {
  display: flex;
  gap: var(--space-xs);
  padding: var(--space-s) 0 0;
}
.targeting-cabins-mode__cabin {
  border: 1px solid $border-color;
  background-color: $bg-gray;
  margin: 0 0 12px;
  padding: 12px;
}
.targeting-cabins-mode__cabin-name {
  font-size: 16px;
  font-weight: 600;
}
.targeting-cabins-mode__cabin-options {
  display: flex;
}
.targeting-cabins-mode__cabin-radio {
  padding: 6px 12px 0;
}
.targeting-cabins-mode__cabins {
  display: flex;
  flex-direction: column;
  flex-basis: var(--targeting-form-max-width);
}
.targeting-cabins-mode__brands {
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 1rem;
  flex-basis: 50%;
}
.rule-origin-destination {
  position: relative;

  .bidirectional-cb {
    position: absolute;
    top: 0;
    right: -38px;
    float: none;
    margin-right: 0;
  }
}
.double-typeahead-search {
  box-sizing: border-box;
  display: inline-block;
  width: 46%;
  &.fs-input-location {
    height: auto;
  }
}
.double-typeahead-divider {
  box-sizing: border-box;
  display: inline-block;
  line-height: 36px;
  text-align: center;
  vertical-align: top;
  width: 8%;
}
.atpco__selected-sub-code {
  margin-bottom: 0px !important;
}
.atpco-suggestion {
  cursor: pointer;
  padding: 6px 12px;
  &:hover,
  &.active {
    background: #e5f4fb;
    color: $link-color;
    .atpco-suggestion__categorization {
      color: $link-color;
    }
  }
}
.atpco-suggestion__categorization {
  color: $medium-gray;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 3px;
}
.atpco-suggestion__sub-group {
  font-style: normal;
  &:before {
    content: "\203A";
    margin-right: 3px;
  }
}
.atpco-suggestion__name {
  line-height: 16px;
  color: $medium-gray;
}
/* ==========================================================================
   UPAs: Media
   ========================================================================== */

#upas_media {
  .sortable-ghost {
    opacity: 0;
  }
  .form-rh fieldset {
    width: 100%;
    dl {
      dt {
        width: 8.33333%;
      }
      dd {
        width: 91.66667%;
      }
    }
  }
  .unselected {
    .unselected-gallery-list {
      li.loader {
        border-color: #e0e0e0;
        > div {
          height: 100px;
          width: 100px;
          padding-top: 37px;
        }
        &:hover {
          border-color: #e0e0e0;
        }
      }
      .media-list-loader {
        padding: 40px 40px 20px;
        &:hover {
          border-color: transparent;
        }
      }
    }
    img {
      height: 100px;
      max-width: 100%;
    }
  }
  .selected {
    @include no-bullet;
    margin: 0;
    li {
      @include group;
      background: #fff;
      border: 1px solid $light-gray;
      margin-bottom: 20px;
      position: relative;
      &:hover {
        border-color: rgba($brand-blue, 0.5);
        .handle {
          border-color: rgba($brand-blue, 0.5);
        }
      }
      &.ui-sortable-helper {
        border-color: $brand-blue;
        opacity: 0.5;
        .handle {
          cursor: move;
          i {
            background: $brand-blue;
          }
        }
      }
      &.placeholder {
        border-color: $brand-blue;
      }
      .csstransitions & {
        transition: border 200ms ease-out;
      }
      .media-translation-hint {
        position: absolute;
        top: 28px;
        left: 100%;
        color: $medium-gray;
        font-size: 16px;
        line-height: 19px;
        margin: 0 0 0 30px;
        width: 240px;
      }
    }
    figure {
      @include group;
      margin: 0;
    }
    img {
      cursor: pointer;
      cursor: -moz-zoom-in;
      cursor: -webkit-zoom-in;
      cursor: zoom-in;
      float: left !important;
      max-width: 100px;
    }
    figcaption {
      float: left;
      label {
        display: inline-block;
        font-size: 14px;
        line-height: 14px;
        margin: 7px 12px;
      }
      textarea {
        border: none;
        border-top: 1px solid $light-gray;
        display: block;
        padding: 4px 12px;
        width: 100%;
        .boxshadow & {
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
      }
    }
    .rh-glyphs-close,
    .rh-icon__close {
      font-size: 12px;
      line-height: 12px;
      top: 8px;
      right: 40px;
    }
    .handle {
      border-left: 2px solid #f8f8f9;
      cursor: -moz-grab;
      cursor: -webkit-grab;
      cursor: grab;
      position: absolute;
      top: 0;
      right: 0;
      left: inherit;
      margin-top: 0;
      height: 100%;
      width: 30px;
      @include default-transition;
      &:hover {
        background: #e5effa;
      }
      &.has-tip {
        border: 0;
      }
      span {
        position: absolute;
        top: 50%;
        left: 8px;
        margin-top: -3px;
        i {
          background: $medium-gray;
          display: block;
          margin-bottom: 2px;
          width: 12px;
          height: 1px;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
    .tooltip {
      width: 210px !important;
    }
    &.no-sort {
      .handle {
        display: none;
      }
      .rh-glyphs-close {
        right: 10px;
      }
      .count {
        right: 37px;
      }
    }
    &.photos,
    &.icons,
    &.infographics,
    &.marketing_graphics {
      figcaption {
        width: calc(100% - 140px);
      }
      &.no-sort {
        figcaption {
          width: calc(100% - 100px);
        }
      }
    }
    &.videos,
    &.tours {
      figure {
        > span {
          display: block;
          float: left;
          max-width: 175px;
        }
      }
      figcaption {
        width: calc(100% - 100px);
      }
      &.no-sort {
        figcaption {
          width: calc(100% - 100px);
        }
      }
    }
  }
  #unselected_photos {
    .or {
      margin: 0 6px 0 15px;
    }
  }
  #unselected_videos,
  #unselected_tours {
    footer {
      .input {
        width: 400px;
      }
      .question {
        margin-right: 10px;
      }
    }
  }
  #unselected_tours {
    footer {
      .input {
        flex: 0 0 25%;
        margin-right: 15px;
      }
    }
  }
}

#upas_media .unselected {
  .list-view {
    figure {
      width: 300px;
    }
    img {
      height: 150px;
    }
  }
}

.media-selection {
  border: 1px solid $light-gray;
  header {
    border-bottom: 1px solid $light-gray;
    @include default-dropshadow;
    padding-bottom: 18px;
  }
  footer {
    background: $bg-gray;
  }
}
.media_inline_alert {
  margin: var(--space-m) 0;
}

cabin-selector {
  max-width: $global-width;
  display: block;
}

.unselected,
.targeting-list {
  header {
    padding: var(--space-m);
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    gap: var(--space-s);
    align-items: center;
  }
  ul {
    list-style: none;
    margin: 0;
  }
}

#upas_basics,
#upas_media,
#upas_targeting {
  header h3 {
    @include paragraph-l(0, 0, 500);
  }
}
